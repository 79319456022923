import React from 'react'
import img from '../Assets/team.svg'
import icon from '../Assets/Link1.svg'
import icon2 from '../Assets/Link-2.svg'
import icon3 from '../Assets/Link-1.svg'
import { Link } from 'react-router-dom'

function OurTeam() {
  return (
    <>
        <div className='team-heading'>
        <h3>OUR TEAM</h3>
        <h2>Meet Our <i>Experts</i></h2>
        <p>Get to know the passionate individuals behind our travel
     <br/>   company, dedicated to crafting unforgettable experiences.</p>
        </div>
        <div className='teams-section'>

       
        <div className='team-div'>
      <div>
      <div className='team-card'>
        <h2>Ayushi Malhotra </h2>
        <h3><i>CEO & Founder </i></h3>
        <li>
        <Link className='none2'  to='#' onClick={(e) => {
      window.location.href = 'mailto:ayushi@haltdmc.com';
      e.preventDefault();
    }}>

        <i class="bi bi-envelope-fill"></i>
        </Link>
        <Link className='none2'>
        <i class="bi bi-facebook"></i>
</Link>
        <Link className='none2' to='https://api.whatsapp.com/send?phone=917838779982'>
            <i class="bi bi-whatsapp"></i>
</Link>
        <Link className='none2'>
            <i class="bi bi-instagram"></i>
</Link>

        </li>
    

</div>
      </div>
      <div>

   <div className='team-card'>
   <h2>Parth Joshi </h2>
        <h3><i>Sales & Operations Head</i></h3>
        <li>
        <Link className='none2'  to='#' onClick={(e) => {
      window.location.href = 'mailto:parth@haltdmc.com ';
      e.preventDefault();
    }}>

        <i class="bi bi-envelope-fill"></i>
        </Link>
        <Link className='none2'>
        <i class="bi bi-facebook"></i>
</Link>
        <Link className='none2' to='https://api.whatsapp.com/send?phone=918126710240 '>
            <i class="bi bi-whatsapp"></i>
</Link>
        <Link className='none2'>
            <i class="bi bi-instagram"></i>
</Link>

        </li>
    

</div>
      </div>
<div>

</div>

   {/* <div className='team-card'>
        <img src={img}/>
        <div className='team-hover'>
        <h2>Nathan Thompson</h2>
        <h3><i>Ceo & Founder</i></h3>
        <li>
            <img src={icon}/>
            <img src={icon2}/>
            <img src={icon3}/>

        </li>

        </div>

</div> */}
        </div>
        </div>
    </>
  )
}

export default OurTeam
